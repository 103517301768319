export default {
    namespaced: true,
    state: {
      activateModal: {
        show: false,
        emit: "",
        id: null,
        text: "",
      },
    },
    getters: {
      getActivate: (state) => state.activateModal,
    },
    mutations: {
  
      TOGGLE_ACTIVATE_MODAL(state, obj) {
        if (obj.show) {
          state.activateModal.id = obj.id ? obj.id : null;
          state.activateModal.emit = obj.emit ? obj.emit : "";
          state.activateModal.text = obj.text ? obj.text : "";
        }
        state.activateModal.show = obj.show;
      },
      
    },
    actions: {
      activateModal({ commit }, obj) {
        commit("TOGGLE_ACTIVATE_MODAL", obj);
      },
    },
  };
  