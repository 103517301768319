<template>
    <v-snackbar
        right
        transition="slide-y-reverse-transition"
        v-model="snackbar.visible"
        :timeout="3000"
        :color="snackbar.color"
    >
        <span class="font-weight-medium text-capitalize"> {{ snackbar.text }}</span>
        <template v-slot:action="{ attrs }">
            <v-btn dark icon @click="snackbar.visible = false" v-bind="attrs">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
</template>
<script>
import { mapState } from "vuex";
export default {
    name: "SnackBar",
    computed: {
        ...mapState("snackBar", ["snackbar"]),
    },
};
</script>
