export default {
    namespaced: true,
    state: {
      createOffer: {
        type: "",
        id: "",
        show: false,
      },
    },
    getters: {
      getCreateOffer: (state) => state.createOffer,
    },
    mutations: {
      CREATE_OFFER(state, obj) {
        if (obj.show) {
          state.createOffer.id = obj.id ? obj.id : null;
          state.createOffer.type = obj.type ? obj.type : "";
        }
        state.createOffer.show = obj.show;
      },
    },
    actions: {
        createOffer({ commit }, obj) {
        commit("CREATE_OFFER", obj);
      },
    },
  };