<template>
  <v-row justify="center">
    <v-overlay v-if="openLoader"
      ><v-progress-circular
        indeterminate
        color="primary"
        size="75"
        width="5"
      ></v-progress-circular
    ></v-overlay>
    <v-dialog
      v-else
      v-model="getChangePassword.show"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-title class="primary">
          <span class="text-h5 white--text">Change Password</span>
          <v-spacer></v-spacer>
          <v-btn
            icon
            text
            @click="changePassword({ show: false, id: '', type: '' })"
          >
            <v-icon class="white--text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form lazy-validation ref="changePasswordForm">
          <v-card-text class="pt-6 pa-0 px-6">
            <v-row no-gutters>
              <v-col cols="12">
                <v-text-field
                  autofocus
                  v-model="current_password"
                  dense
                  outlined
                  label="Enter Old Password"
                  :type="showOldPassword ? 'text' : 'password'"
                  :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showOldPassword = !showOldPassword"
                  :rules="[rules.required]"
                  @keyup.enter="submitChangePassword()"
                 
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="new_password"
                  dense
                  outlined
                  label="Enter New Password"
                  :type="showNewPassword ? 'text' : 'password'"
                  :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showNewPassword = !showNewPassword"
                  :rules="[rules.required]"
                  @keyup.enter="submitChangePassword()"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  outlined
                  label="Re-enter New Password"
                  :type="reEnterPassword ? 'text' : 'password'"
                  :append-icon="reEnterPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="reEnterPassword = !reEnterPassword"
                  v-model="confirm_password"
                  :rules="[rules.required, matchPassword]"
                  @keyup.enter="submitChangePassword()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              class="green white--text mr-2 capitalize"
              :loading="loading"
              @click="submitChangePassword()"
              >Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import RULES from "@/common/fieldRules";
import apiUrl from "@/common/apiUrl/index.js";
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    openLoader: false,
    new_password: "",
    current_password: "",
    confirm_password: "",
    showOldPassword: false,
    showNewPassword: false,
    reEnterPassword: false,
    rules: RULES,
    loading: false,
  }),
  computed: {
    ...mapGetters({
      getChangePassword: "auth/getChangePassword",
    }),
    show: {
      get() {
        if (this.getChangePassword.show) return this.getChangePassword.show;
        else return null;
      },
      set(value) {
        this.changePassword({ show: value });
      },
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.closeModal();
    },
    current_password(){
        this.showNewPassword= false;
    this.reEnterPassword= false;
      },
      new_password(){
        this.reEnterPassword= false;
        this.showOldPassword=false;
      },
      confirm_password(){
        this.showNewPassword= false;
        this.showOldPassword=false;
  },
  },
  methods: {
    ...mapActions({
      changePassword: "auth/changePassword",
      showSnackbar: "snackBar/showSnackbar",
    }),
    // showp(){
    //   this.showNewPassword= false;
    // this.reEnterPassword= false;
    // },
    matchPassword(value) {
      if (value === this.new_password) {
        return true;
      } else {
        return "The password confirmation does not match";
      }
    },
    openModal() {},
    closeModal() {
      this.$refs.changePasswordForm.reset();
      (this.current_password = ""),
        (this.new_password = ""),
        (this.confirm_password = "");
    },
    
    submitChangePassword() {
      if (this.$refs.changePasswordForm.validate()) {
        // if (this.new_password === this.confirm_password) {
        this.loading = true;
        const successHandler = (res) => {
          this.loading = false;
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });
          this.changePassword({ show: false });
        };

        const failureHandler = () => {
          this.loading = false;
        };
        let formData = {};
        formData["current_password"] = this.current_password;
        formData["new_password"] = this.new_password;
        formData["confirm_password"] = this.confirm_password;
        return this.$Axios.request_PATCH(
          apiUrl.team_update_password,
          formData,
          {},
          {},
          successHandler,
          failureHandler,
          false
        );
        // }
      }
    },
  },
};
</script>
