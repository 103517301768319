<template>
    <v-app>
        <v-main>
            <component :is="this.$route.meta.layout || 'div'">
                <router-view />
            </component>
            <snack-bar/>
            <page-loader/>
        </v-main>
    </v-app>
</template>

<script>
import PageLoader from './components/pageLoader/pageLoader.vue';
import snackBar from './components/snackBar/snackBar.vue';
export default {
    components: { snackBar, PageLoader },
    name: "App",

    data: () => ({
        //
    }),
};
</script>

<style scoped>
@import url("./assets/style.css");
</style>
