const apiUrls = {
  // User Detail
  user_detail: "/api/current_user/",

  // Team Profile
  team_login: "/team/login/",
  team_forgot_password: "/team/forgot-password/",
  team_password_token_check: "/team/password-token-check",
  team_new_password: "/team/new-password/",
  refresh__token: "/api/token/refresh/",

  // commodities
  commodities: "/commodities/",

  //Facility Center
  facility_center: "/facility_center/",

  //master
  unit: "/master/unit_master/",
  city: "/master/cities/",
  state: "/master/states/",
  district: "/master/districts/",
  reason_list: "/master/reason_list/",
  crop: "/master/crops/",
  bank: "/master/bank_master/",
  contract_reason_list: "/master/contract_reason_list/",
  irrigation_type_list: "master/irrigation-type-list/",
  irrigation_source_list: "master/irrigation-source-list/",
  equipment_list: "master/equipment-list/",
  verification_reason_list: "/master/verification-reason-list/",
  farm_reason_list: "/master/farm-reason-list/",
  crop_reason_list: "/master/crop-reason-list/",

  //pincode url
  pin_code: "https://api.postalpincode.in/pincode/",

  //App Users
  framers: "/app_users/farmers/",
  app_users: "/app_users/",
  app_users_farmers: "/app_users/farmers/",
  app_users_traders: "/app_users/traders/",
  users_block_status: "/app_users/users/block_status/",
  app_users_all: "/app_users/all/",
  user_farm_list: "/app_users/farm-list/",
  app_user_verification: "/app_users/verification/",
  app_user_agent_assign: "app_users/agent-assign/",
  app_user_farmer_detail: "/app_users/farmers/detail/",
  app_user_trader_detail: "/app_users/traders/detail/",

  //teamManagement
  team: "/team/",
  team_update_password: "/team/update-password/",
  team_agent_list: "/team/agent-list/",
  //auction

  auctions: "/auctions/",

  //offers
  offers: "/offers/",

  //image
  image: "/api/image/",

  //contracts
  contracts: "/contracts/",
  status: "/contracts/status/",

  //mandi
  mandi: "/mandi/mandi-list/",
  mandi_detail: "/mandi/mandi-detail/",
  mandi_list: "/mandi/mandi-list-short/",

  //mandi-rate
  mandi_rate_list: "/mandi/mandi-rate-list/",
  mandi_rate_detail: "/mandi/mandi-rate-detail/",

  //farmMangement
  farm_list: "/app_users/farm-list/",
  farm_verification: "/app_users/farm-verification/",

  //cropManagement
  farmer_crop_list: "/app_users/farmer-crop-list/",
  farmer_crop_verification: "/app_users/farmer-crop-verification/",

  //export 
  farmer_export: "/app_users/farmers/export/",
  trader_export: "/app_users/traders/export/",
  farmManagement_export: "/app_users/farms/export/",
  cropManagement_export: "/app_users/farmer-crops/export/",
};


export default apiUrls;
