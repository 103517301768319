export default {
  namespaced: true,
  state: {
    addCommodity: {
      type: "",
      id: "",
      show: false,
    },
  },
  getters: {
    getAddCommodity: (state) => state.addCommodity,
  },
  mutations: {
    ADD_COMMODITY(state, obj) {
      if (obj.show) {
        state.addCommodity.id = obj.id ? obj.id : null;
        state.addCommodity.type = obj.type ? obj.type : "";
      }
      state.addCommodity.show = obj.show;
    },
  },
  actions: {
    addCommodity({ commit }, obj) {
      commit("ADD_COMMODITY", obj);
    },
  },
};
