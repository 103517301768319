<template>
  <div>
    <v-app-bar class="white pr-5 pl-2" height="54px" app style="z-index: 10">
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.xsOnly"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <span
        v-if="
          $route.matched.length > 1
            ? $route.matched[0].path == $route.path
              ? false
              : true
            : false
        "
        class="capitalize font-weight-bold"
      >
        <button icon @click="$router.back()">
          <v-icon small class="black--text font-weight-bold pa-1"
            >mdi-arrow-left</v-icon
          >
        </button>
        {{ this.$route.meta.title }}
      </span>

      <v-spacer></v-spacer>
      <v-avatar size="35">
        <v-img
          v-if="user_detail.profile_picture"
          :src="user_detail.profile_picture"
          :lazy-src="require('@/assets/logo/loading.gif')"
        />
      </v-avatar>
      <v-avatar
        class="primary white--text pa-1 capitalize"
        size="35"
        v-if="!user_detail.profile_picture"
        >{{ user_detail?.name?.charAt(0) }}</v-avatar
      >
      <span class="ml-2 capitalize">{{ user_detail.name }}</span>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon dark v-bind="attrs" v-on="on">
            <v-icon class="black--text">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <div class="text-center white" style="min-width: 200px">
          <div class="pa-1 pt-4">
            <div
              style="display: block; margin-left: auto; margin-right: auto"
              class="pt-2 pb-2"
            >
              <v-avatar size="35" v-if="user_detail.profile_picture">
                <v-img
                  :src="user_detail.profile_picture"
                  :lazy-src="require('@/assets/logo/loading.gif')"
                />
              </v-avatar>
              <v-avatar class="primary white--text capitalize" size="35" v-else
                >{{ user_detail?.name?.charAt(0) }}
              </v-avatar>
            </div>

            <span class="font-weight-bold capitalize">{{
              user_detail.name
            }}</span
            ><br />
            <span class="fs-14">{{ user_detail.email }}</span>
            <br />
            <span class="fs-14">{{ user_detail.mobile_number }}</span>
          </div>
          <v-divider class="primary"></v-divider>
          <div class="pa-4 font-weight-bold fs-13">
            <span
              class="pointer ls-1 changePassword-btn px-4 py-2 rounded-pill"
              @click="
                changePassword({
                  show: true,
                  id: '',
                  type: 'change',
                })
              "
              >CHANGE PASSWORD</span
            ><br />
            <span
              class="pointer ls-1 logout-btn px-15 py-2 rounded-pill"
              @click="logout()"
              >LOGOUT</span
            >
          </div>
        </div>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      :permanent="$vuetify.breakpoint.smAndUp"
      :temporary="$vuetify.breakpoint.xsOnly"
      app
      class="primary py-5"
      height="100%"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            <v-img
              icon
              src="@/assets/Group 7985.svg"
              height="100"
              width="100"
              class="ml-13 mb-3"
            />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list dense nav class="pl-0 pr-0">
        <v-list-item
          v-for="item in navLinks"
          :key="item.name"
          :to="item.to"
          link
          class="white--text fs-12"
          active-class="primary--text rounded-0 elevation-2 active-class"
        >
          <v-list-item-icon class="ml-7">
            <v-icon class="color-white" v-if="item.icon">{{
              item.icon
            }}</v-icon>
            <div v-else>
              <v-img
                :src="url + item.image"
                v-show="$route?.path == item.to"
                style="width: 25px; height: 25px"
              />
              <v-img
                :src="url + item.image_light"
                v-show="$route?.path != item.to"
                style="width: 25px; height: 25px"
              />
            </div>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <changePassword />

    <router-view class="grey pt-5" style="min-height: 100vh" />
  </div>
</template>

<script>
import changePassword from "@/views/auth/changePassword.vue";
// import Axios from '@/api/BaseAxios.js'
import authToken from "@/common/authToken.js";
import _ from "lodash";
import { mapActions } from "vuex";
export default {
  components: {
    changePassword,
  },
  data() {
    return {
      url: "",
      drawer: false,
      user_detail: {},
      user_type: "",
      // navLinks: [
      //   {
      //     name: "Dashboard",
      //     icon: "mdi-view-dashboard",
      //     to: "/",
      //   },
      //   {
      //     name: "Buy Contracts",
      //     icon: "",
      //     image: "/media/icons/Buycontract.svg",
      //     image_light: "/media/icons/Buycontract-white.svg",
      //     to: "/buy-contract",
      //   },
      //   {
      //     name: "Sale Contracts",
      //     icon: "",
      //     image: "/media/icons/Salecontract.svg",
      //     image_light: "/media/icons/Salecontract-white.svg",
      //     to: "/sale-contract",
      //   },
      //   {
      //     name: "Commodities",
      //     icon: "mdi-sprout",
      //     to: "/commodities",
      //   },
      //   {
      //     name: "Auction Rooms",
      //     icon: "",
      //     image: "/media/icons/Auction.svg",
      //     image_light: "/media/icons/Auction-white.svg",
      //     to: "/auction",
      //   },
      //   {
      //     name: "Offers",
      //     icon: "mdi-currency-inr",
      //     to: "/offers",
      //   },
      //   {
      //     name: "Farmers",
      //     icon: "mdi-account-arrow-up-outline",
      //     to: "/farmers",
      //   },
      //   {
      //     name: "Traders",
      //     icon: "mdi-account-convert-outline",
      //     to: "/traders",
      //   },
      //   {
      //     name: "Facility Center",
      //     icon: "mdi-store",
      //     to: "/facility-center",
      //   },
      //   {
      //     name: "Crops Master",
      //     icon: "mdi-leaf-circle",
      //     to: "/crop-master",
      //   },
      //   {
      //     name: "Crops Management",
      //     icon: "mdi-sprout-outline",
      //     to: "/crop-management",
      //   },
      //   {
      //     name: "Farm Management",
      //     icon: "mdi-tractor",
      //     to: "/farm-management",
      //   },
      //   {
      //     name: "Team Management",
      //     icon: "mdi-account-group",
      //     to: "/team-management",
      //   },
      //   {
      //     name: "Unit Master",
      //     icon: "mdi-cog",
      //     to: "/unit-master",
      //   },
      //   {
      //     name: "Mandis",
      //     icon: "mdi-storefront-outline",
      //     to: "/mandis",
      //   },
      //   {
      //     name: "Mandi Rates",
      //     icon: "mdi-chart-line",
      //     to: "/mandi-rate",
      //   },
      // ],
    };
  },

  created() {
    const successHandler = (res) => {
      this.user_detail = res.data.data;
      this.$store.commit("userDetail/USER_DETAIL_SET", {
        id: res.data.data.id,
        user_type: res.data.data.user_type,
      });
      // localStorage.setItem("userType", res.data.data.user_type);
      // this.removeItem();
    };
    const failureHandler = () => {};
    let formData = {};
    this.$Axios.request_GET(
      this.$api_urls.user_detail,
      formData,
      {},
      successHandler,
      failureHandler,
      false
    );
  },
  mounted() {
    this.url = process.env.VUE_APP_BASE_URL;
  },
  computed: {
    navLinks() {
      const nav_tab = [
        {
          name: "Dashboard",
          icon: "mdi-view-dashboard",
          to: "/",
        },
        {
          name: "Buy Contracts",
          icon: "",
          image: "/media/icons/Buycontract.svg",
          image_light: "/media/icons/Buycontract-white.svg",
          to: "/buy-contract",
        },
        {
          name: "Sale Contracts",
          icon: "",
          image: "/media/icons/Salecontract.svg",
          image_light: "/media/icons/Salecontract-white.svg",
          to: "/sale-contract",
        },
        {
          name: "Commodities",
          icon: "mdi-sprout",
          to: "/commodities",
        },
        {
          name: "Auction Rooms",
          icon: "",
          image: "/media/icons/Auction.svg",
          image_light: "/media/icons/Auction-white.svg",
          to: "/auction",
        },
        {
          name: "Offers",
          icon: "mdi-currency-inr",
          to: "/offers",
        },
        {
          name: "Farmers",
          icon: "mdi-account-arrow-up-outline",
          to: "/farmers",
        },
        {
          name: "Traders",
          icon: "mdi-account-convert-outline",
          to: "/traders",
        },
        {
          name: "Facility Center",
          icon: "mdi-store",
          to: "/facility-center",
        },
        {
          name: "Crops Master",
          icon: "mdi-leaf-circle",
          to: "/crop-master",
        },
        {
          name: "Crops Management",
          icon: "mdi-sprout-outline",
          to: "/crop-management",
        },
        {
          name: "Farm Management",
          icon: "mdi-tractor",
          to: "/farm-management",
        },
        {
          name: "Team Management",
          icon: "mdi-account-group",
          to: "/team-management",
        },
        {
          name: "Unit Master",
          icon: "mdi-cog",
          to: "/unit-master",
        },
        {
          name: "Mandis",
          icon: "mdi-storefront-outline",
          to: "/mandis",
        },
        {
          name: "Mandi Rates",
          icon: "mdi-chart-line",
          to: "/mandi-rate",
        },
      ];
      return _.filter(nav_tab, (item) => {
        if (
          item.to !== "/" &&
          item.to !== "/traders" &&
          item.to !== "/mandi-rate" &&
          this.current_user_type == "trader_mitra"
        )
          return;
        if (
          item.to !== "/" &&
          item.to !== "/farmers" &&
          item.to !== "/crop-management" &&
          item.to !== "/farm-management" &&
          this.current_user_type == "kisan_mitra"
        )
          return;
        if (
          item.to == "/team-management" &&
          this.current_user_type == "manager"
        )
          return;
        if (
          item.to == "/team-management" &&
          this.current_user_type == "executive"
        )
          return;
        return item;
      });
    },
    current_user_type() {
      return this.$store.state.userDetail.userDetail.user_type;
    },
  },
  methods: {
    ...mapActions({
      changePassword: "auth/changePassword",
    }),
    logout() {
      authToken.removeToken();
      // localStorage.removeItem("userType");
      this.$router.push({ name: "login" });
    },
    // hasHistory() {
    //   this.$router.go(-1);
    // },
    
    // removeItem() {
    //   if (
    //     this.$store.state.userDetail.userDetail.user_type == "manager" ||
    //     this.$store.state.userDetail.userDetail.user_type == "executive"
    //   ) {
    //     let obj = this.navLinks.find((item) => item.to == "/team-management");
    //     let objIndex = this.navLinks.indexOf(obj);
    //     this.navLinks.splice(objIndex, 1);
    //   }
    // },
  },
};
</script>
<style scoped>
.nav-drawer {
  width: 10px;
}
.active-class {
  border-right: 8px solid #ffb800;
  background-color: #ffffff;
}
.color-white {
  color: white;
}
.changePassword-btn {
  line-height: 3;
}
.changePassword-btn:hover {
  background: #e3e3e3;
}
.logout-btn:hover {
  background: #e3e3e3;
}
.image-class {
  fill: #ed6866;
}
</style>
