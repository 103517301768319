export default {
    namespaced: true,
    state: {
      addMandiRate: {
        type: "",
        id: "",
        show: false,
      },
    },
    getters: {
      getAddMandiRate: (state) => state.addMandiRate,
    },
    mutations: {
      ADD_MANDI_RATE(state, obj) {
        if (obj.show) {
          state.addMandiRate.id = obj.id ? obj.id : null;
          state.addMandiRate.type = obj.type ? obj.type : "";
        }
        state.addMandiRate.show = obj.show;
      },
    },
    actions: {
        addMandiRate({ commit }, obj) {
        commit("ADD_MANDI_RATE", obj);
      },
    },
  };
  