const authToken = {
    setToken: (accessToken = null, refreshToken = null) => {
        if (accessToken) {
            localStorage.setItem("accessToken", accessToken);
        }
        if (refreshToken) {
            localStorage.setItem("refreshToken", refreshToken);
        }
    },
    getToken: () => {
        return {
            accessToken: localStorage.getItem("accessToken"),
            refreshToken: localStorage.getItem("refreshToken"),
        };
    },
    removeToken: () => {
        localStorage.clear();
    },
};
export default authToken;
