import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import  urls  from './common/apiUrl/index'
import  utils  from './helper/utils'
import apiKeys from './common/apiKeys/index'
import Axios from "@/api/BaseAxios";

Vue.config.productionTip = false
Vue.prototype.$api_urls = urls;
Vue.prototype.$api_keys = apiKeys;
Vue.prototype.$utils = utils;
Vue.prototype.$Axios = Axios;

new Vue({
  router,
  store,
  // Axios,
  vuetify,
  render: h => h(App)
}).$mount('#app')
