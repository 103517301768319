<template>
    <v-dialog v-model="isPageLoad.show" hide-overlay persistent width="300">
        <v-overlay>
            <v-card color="primary" dark>
                <v-card-text>
                    {{isPageLoad.message}}
                    <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-overlay>
        </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: "pageLoader",
    data() {
        return {
            dialog: true,
        };
    },
    computed: {
        ...mapGetters({
            isPageLoad:'app/getIsPageLoader'
        })
    },
    methods: {},
};
</script>
<style scoped></style>
