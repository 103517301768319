export default {
  required: (value) => !!value || "This is Required.",
  select: (v) => {
    if (Object.keys(v || {})[0]) {
      return true;
    } else {
      return "This is Required";
    }
  },
  min_length: (v) => {
    const re = /[0-9a-zA-Z]{2,}/;
    if (re.test(v)) {
      return true;
    } else if (!v) {
      return true;
    } else {
      return "Name should contain atleast 2 character";
    }
  },
  email: (v) => {
    /* eslint-disable-next-line no-useless-escape*/
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(String(v).toLowerCase().trim())) {
      return true;
    }
    return "Invalid Email";
  },
  name: (v) => {
    const re = /^[A-Za-z\s]*$/;
    if (re.test(v)) {
      return true;
    }
    return "Name should contain only alphabets";
  },
  aplhaNumeric: (v) => {
    const re = /^[a-zA-Z0-9_]*$/;
    if (re.test(v)) {
      return true;
    }
    return "Field should contain only alphabets and numbers.";
  },
  phone: (v) => {
    /* eslint-disable-next-line no-useless-escape*/
    const re = /^(\+91)?[0]?(91)?[6789]\d{9}$/;
    if (v < 0) return "Mobile number can't be negative";
    if (v?.length != 10) return "Number should be 10 digits only";
    if (re.test(v)) {
      return true;
    }
    return "Invalid Phone Number";
  },
  pin_code: (v) => {
    if (!v) return true;
    // const re = /^[1-9]{1}\d{2}\s?\d{3}$/;
    const re = /^\d{6}$/;
    if (re.test(v)) {
      return true;
    }
    return "Invalid Pincode";
  },
  ifsc: (v) => {
    if (v?.length == 0) return true;
    const re = /^[A-Z]{4}0[A-Z0-9]{6}$/;

    if (re.test(v)) {
      return true;
    }
    return "Invalid IFSC code";
  },
  adhar_number: (v) => {
    if (!v) return true;
    const re = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
    if (re.test(v)) {
      return true;
    }
    return "Invalid Aadhaar number";
  },
  pan_number: (v) => {
    const re = /^[A-Z]{5}[0-9]{4}[A-Z]{1}/;
    if (v === "") {
      return true;
    } else if (re.test(v)) {
      return true;
    } else {
      return "Invalid Pan number";
    }
  },
  account_number: (v) => {
    if (v?.length == 0) return true;
    const re = /^\d{9,18}$/;
    if (re.test(v)) {
      return true;
    }
    return "Invalid account number";
  },
  remove_space: (v) => {
    if (!v?.replace(/\s/g, "").length) {
      if (!v) {
        return true;
      }
      return "Invalid Text";
    } else {
      return true;
    }
  },
  decimal_number: (v) => {
    const re = /^[0-9]{1,6}$/;
    if (re.test(v)) {
      return true;
    } else if (!v) {
      return true;
    } else {
      return "Invalid";
    }
  },
  two_digit_decimal: (v) => {
    const re = /^[0-9]{0,6}(\.[0-9]{1,2})?$/;
    if (re.test(v)) {
      return true;
    } else {
      return "invalid";
    }
  },
  blockInvalidChar: (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault(),
};
