export default {
    namespaced: true,
    state: {
        isCancel: false,
        isPageLoader: {
            show: false,
            message: "",
        },
    },
    getters: {
        getIsCancel: (state) => state.isCancel,
        getIsPageLoader: (state) => state.isPageLoader,
    },
    mutations: {
        IS_CANCEL(state, obj) {
            state.isCancel = obj;
        },
        isPageLoader(state, obj) {
            state.isPageLoader.show = obj.show ? obj.show : false;
            state.isPageLoader.message = obj.message ? obj.message : "";
        },
    },
    actions: {
        isCancel({ commit }, item) {
            commit("IS_CANCEL", item);
        },
    },
};
