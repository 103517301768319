export default {
  namespaced: true,
  state: {
    cropMaintab: 0,
    selectedCropTab: "pending",
    rejectReason: {
      type: "",
      id: "",
      show: false,
    },
  },
  getters: {
    getRejectReason: (state) => state.rejectReason,
  },
  mutations: {
    REJECT_REASON(state, obj) {
      if (obj.show) {
        state.rejectReason.id = obj.id ? obj.id : null;
        state.rejectReason.type = obj.type ? obj.type : "";
      }
      state.rejectReason.show = obj.show;
    },
  },
  actions: {
    rejectReason({ commit }, obj) {
      commit("REJECT_REASON", obj);
    },
  },
};
