export default {
  namespaced: true,
  state: {
    addFacilityCenter: {
      type: "",
      id: "",
      show: false,
    },
  },
  getters: {
    getAddFacilityCenter: (state) => state.addFacilityCenter,
  },
  mutations: {
    ADD_FACILITYCENTER(state, obj) {
      if (obj.show) {
        state.addFacilityCenter.id = obj.id ? obj.id : null;
        state.addFacilityCenter.type = obj.type ? obj.type : "";
      }
      state.addFacilityCenter.show = obj.show;
    },
  },
  actions: {
    addFacilityCenter({ commit }, obj) {
      commit("ADD_FACILITYCENTER", obj);
    },
  },
};
