import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#085A52",
                secondary: "#F0F0F0",
                green: "#069F6A",
                // error: "#C64A4A",
                grey: "#E3E3E3",
                yellow: "#FFB800",
                black: "#000000",
                confirmColor:"#3F6AAB",
                neutralGray: "#333333",
                statusColorPurple : "#A05CE6",
                statusColorBlue:"#4685FF",
            }
        }
    }
});
