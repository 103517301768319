export default {
    namespaced: true,
    state: {
      deleteModal: {
        show: false,
        emit: "",
        id: null,
        text: "",
      },
    },
    getters: {
      getShow: (state) => state.deleteModal,
    },
    mutations: {
  
      TOGGLE_DELETE_MODAL(state, obj) {
        if (obj.show) {
          state.deleteModal.id = obj.id ? obj.id : null;
          state.deleteModal.emit = obj.emit ? obj.emit : "";
          state.deleteModal.text = obj.text ? obj.text : "";
        }
        state.deleteModal.show = obj.show;
      },
      
    },
    actions: {
      deleteModal({ commit }, obj) {
        commit("TOGGLE_DELETE_MODAL", obj);
      },
    },
  };
  