export default {
    namespaced: true,
    state: {
      createBuyContract: {
        type: "",
        id: "",
        show: false,
      },
      cancelReason: {
        type: "",
        id: "",
        show: false,
      }
    },
    getters: {
      getBuyContract: (state) => state.createBuyContract,
      getcancelReason: (state) => state.cancelReason,
    },
    mutations: {
      CANCEL_REASON(state, obj1){
        if(obj1.show) {
          state.cancelReason.id = obj1.id ? obj1.id : null;
          state.cancelReason.type = obj1.id ? obj1.type : "";
        }
        state.cancelReason.show = obj1.show;
      },
      CREATE__BUY_CONTRACT(state, obj) {
        if (obj.show) {
          state.createBuyContract.id = obj.id ? obj.id : null;
          state.createBuyContract.type = obj.type ? obj.type : "";
        }
        state.createBuyContract.show = obj.show;
      },
    },
    actions: {
      createBuyContract({ commit }, obj) {
        commit("CREATE__BUY_CONTRACT", obj);
      },
      cancelReason({ commit }, obj1){
        commit("CANCEL_REASON", obj1);
      }
    },
  };