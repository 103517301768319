export default {
    namespaced: true,
    state: {
      snackbar: {
        visible: false,
        title: '',
        color: 'primary'
      },
    },
    mutations: {
      SET_SNACKBAR(state, snackbar) {
        state.snackbar = snackbar;
      },
    },
    actions: {
      showSnackbar({ commit }, snackbar) {
        snackbar.visible = true;
        snackbar.color = snackbar.color || "success";
        commit("SET_SNACKBAR", snackbar);
      },
    },
    getters: {},
  };