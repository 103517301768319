import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import authLayout from "../layout/auth/authLayout";
import appLayout from "@/layout/app/appLayout";
import authToken from "@/common/authToken.js";
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    meta: {
      isPublic: true,
      title: "Login",
      layout: authLayout,
    },
    component: () => import("@/views/auth/logIn"),
  },
  {
    path: "/reset-password/:uid/:token",
    name: "resetPassword",
    meta: {
      isPublic: true,
      title: "Reset Password",
      layout: authLayout,
    },
    component: () => import("@/views/auth/resetPassword"),
  },
  {
    path: "/forget-password",
    name: "forgotPassword",
    meta: {
      isPublic: true,
      title: "Forget Password",
      layout: authLayout,
    },
    component: () => import("@/views/auth/forgetPassword"),
  },
  {
    path: "/",
    name: "Dashboard",
    meta: {
      isPublic: false,
      title: "Dashboard",
      layout: appLayout,
    },
    component: () => import("@/views/app/dashBoard"),
  },
  {
    path: "/buy-contract",
    name: "buyContracts",
    meta: {
      isPublic: false,
      title: "Buy Contracts",
      layout: appLayout,
    },
    component: () => import("@/views/app/buyContract/buyContract"),
  },
  {
    path: "/sale-contract",
    name: "saleContracts",
    meta: {
      isPublic: false,
      title: "Sale Contracts",
      layout: appLayout,
    },
    component: () => import("@/views/app/saleContract/saleContract"),
  },
  {
    path: "/commodities",
    name: "Commodities",
    meta: {
      isPublic: false,
      title: "Commodities",
      layout: appLayout,
    },
    component: () => import("@/views/app/commodities/commodiTies"),
  },
  {
    path: "/auction",
    name: "Auction_room",
    meta: {
      isPublic: false,
      title: "Auction Room",
      layout: appLayout,
    },
    component: () => import("@/views/app/Auction/auctionRoom.vue"),
  },
  {
    path: "/offers",
    name: "Offers",
    meta: {
      isPublic: false,
      title: "Offers",
      layout: appLayout,
    },
    component: () => import("@/views/app/offers/offerModule"),
  },
  {
    path: "/farmers",
    name: "Farmer",
    meta: {
      isPublic: false,
      title: "Farmers",
      layout: appLayout,
    },
    component: () => import("@/views/app/farmers/framerRoute"),
    children: [
      {
        path: "",
        name: "Farmers",
        meta: {
          isPublic: false,
          title: "Farmers",
          layout: appLayout,
        },
        component: () => import("../views/app/farmers/farMers"),
      },
      {
        path: "add",
        name: "addFarmers",
        meta: {
          isPublic: false,
          title: "Add Farmer",
          layout: appLayout,
        },
        component: () => import("@/views/app/farmers/addFarmer"),
      },
      {
        path: "edit/:id",
        name: "editFarmers",
        meta: {
          isPublic: false,
          title: "Edit Farmer",
          layout: appLayout,
        },
        component: () => import("@/views/app/farmers/addFarmer"),
      },
      {
        path: "view/:id",
        name: "viewProfile",
        meta: {
          isPublic: false,
          title: "view Farmer",
          layout: appLayout,
        },
        component: () => import("@/views/app/farmers/viewProfile"),
      },
    ],
  },
  {
    path: "/traders",
    name: "Trader",
    meta: {
      isPublic: false,
      title: "Trader",
      layout: appLayout,
    },
    component: () => import("../views/app/traders/traderRoute"),
    children: [
      {
        path: "",
        name: "Traders",
        meta: {
          isPublic: false,
          title: "Trader",
          layout: appLayout,
        },
        component: () => import("@/views/app/traders/traderS"),
      },
      {
        path: "add",
        name: "addTrader",
        meta: {
          isPublic: false,
          title: "Add Trader",
          layout: appLayout,
        },
        component: () => import("@/views/app/traders/addTrader"),
      },
      {
        path: "edit/:id",
        name: "editTrader",
        meta: {
          isPublic: false,
          title: "Edit Trader",
          layout: appLayout,
        },
        component: () => import("@/views/app/traders/addTrader"),
      },
      {
        path: "view/:id",
        name: "viewTraderProfile",
        meta: {
          isPublic: false,
          title: "View Trader",
          layout: appLayout,
        },
        component: () => import("@/views/app/traders/viewTrader"),
      },
    ],
  },
  {
    path: "/facility-center",
    name: "facilitycenter",
    meta: {
      isPublic: false,
      title: "Facility Center",
      layout: appLayout,
    },
    component: () => import("@/views/app/facilityCenter/facilityCenter"),
  },
  {
    path: "/crop-master",
    name: "cropmaster",
    meta: {
      isPublic: false,
      title: "Crop Master",
      layout: appLayout,
    },
    component: () => import("@/views/app/cropMaster/cropMaster"),
  },
  {
    path: "/crop-management",
    name: "cropmanagement",
    meta: {
      isPublic: false,
      title: "Crop Management",
      layout: appLayout,
    },
    component: () => import("@/views/app/cropManagement/cropManagement"),
  },
  {
    path: "/farm-management",
    name: "farmmanagement",
    meta: {
      isPublic: false,
      title: "Fram Management",
      layout: appLayout,
    },
    component: () => import("@/views/app/farmManagement/farmManagement"),
  },
  {
    path: "/team-management",
    name: "teamManagement",
    meta: {
      isPublic: false,
      title: "Team Management",
      layout: appLayout,
    },
    component: () => import("@/views/app/teamManagement/teamManagement"),
  },

  {
    path: "/unit-master",
    name: "unitMaster",
    meta: {
      isPublic: false,
      title: "Unit Master",
      layout: appLayout,
    },
    component: () => import("@/views/app/unitMaster/unitMaster"),
  },

  {
    path: "/mandis",
    name: "mandi",
    meta: {
      isPublic: false,
      title: "Mandis",
      layout: appLayout,
    },
    component: () => import("@/views/app/mandi/mandiModule"),
  },
  {
    path: "/mandi-rate",
    name: "mandiRates",
    meta: {
      isPublic: false,
      title: "Mandi rates",
      layout: appLayout,
    },
    component: () => import("@/views/app/mandiRate/mandiRate"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) =>
    new Promise(() => {
      const position = savedPosition || {};
      if (!savedPosition) {
        if (to.hash) {
          position.selector = to.hash;
        }
        if (to.matched.some((m) => m.meta.scrollToTop)) {
          position.x = 0;
          position.y = 0;
        }
      }
      window.scrollTo(position.x, position.y);
    }),
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
    ? `${to.meta.title} — KrishiUddant`
    : "krishiUddant";
  store.state.app.breadcrumbItems = [];
  store.state.app.breadcrumbItems.push({
    text: to.meta.title,
    href: to.path,
  });
  const { accessToken, refreshToken } = authToken.getToken();
  if (
    accessToken &&
    refreshToken &&
    (to.name === "login" ||
      to.name === "resetPassword" ||
      to.name === "forgotPassword")
  )
    next({ name: "Dashboard" });
  else if (!to.meta.isPublic) {
    if (!accessToken && !refreshToken) {
      next({ name: "login" });
    } else next();
  } else next();

  if (
    !to.meta.isPublic &&
    to.name === "teamManagement" &&
    this.$store.state.userDetail.userDetail.user_type == "manager" &&
    this.$store.state.userDetail.userDetail.user_type == "executive"
  ) {
    next({ name: "Dashboard" });
    
    
  }
  // if (
  //   !to.meta.isPublic &&
  //   to.name != "Dashboard" &&
  //   to.name != "Trader" &&
  //   to.name != "mandiRates" &&
  //   this.$store.state.userDetail.userDetail.user_type == "trader_mitra"
  // ) {
    
  //   next({ name: "Dashboard" });
  // }
});

export default router;
