<template>
    <div class="left-col">
        <v-row no-gutters>
            <v-col cols="" md="7" sm="4"></v-col>
            <v-col cols="12" md="5" sm="8">
                <v-card class="px-13 py-15 cards" style="min-height:100vh">
                    <img :src="logo" width="80" height="80" class="mb-3 ml-4 mt-12"/>
                    <router-view></router-view>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import krishiUddantLogo from "@/assets/logo/krishiUddantLogo.svg";

export default {
    data() {
        return {
            logo: krishiUddantLogo,
        };
    },
};
</script>
<style scoped>
.left-col {
    background: url("@/assets/logo/login_background") no-repeat center center
        fixed;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
}
.cards{
    position:fixed;
}
</style>
