export default {
  namespaced: true,
  state: {
    createSaleContract: {
      type: "",
      id: "",
      show: false,
    },
    cancelSaleReason: {
      type: "",
      id: "",
      show: false,
    },
  },
  getters: {
    getSaleContract: (state) => state.createSaleContract,
    getcancelSaleReason: (state) => state.cancelSaleReason,
  },
  mutations: {
    CREATE_SALE_CONTRACT(state, obj) {
      if (obj.show) {
        state.createSaleContract.id = obj.id ? obj.id : null;
        state.createSaleContract.type = obj.type ? obj.type : "";
      }
      state.createSaleContract.show = obj.show;
    },
    CANCEL_SALE_REASON(state, obj1){
        if(obj1.show){
            state.cancelSaleReason.id = obj1.id ? obj1.id : null;
            state.cancelSaleReason.type = obj1.type ? obj1.type : "";
        }
        state.cancelSaleReason.show = obj1.show;
    }
  },
  actions: {
    createSaleContract({ commit }, obj) {
      commit("CREATE_SALE_CONTRACT", obj);
    },
    cancelSaleReason({ commit }, obj1) {
        commit("CANCEL_SALE_REASON", obj1);
    }
  },
};
