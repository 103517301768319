export default {
    namespaced: true,
    state: {
      farmermainSelectedTab: 0,
      selectedfarmermaintab: "pending",
      addDocument: {
        type: "",
        id: "",
        show: false,
        documentUrl: {
          front: '',
          back: '',
          pan: '',
        },

      },
    },
    getters: {
      getAddDocument: (state) => state.addDocument,

    },
    mutations: {
      ADD_DOCUMENT(state, obj) {
        if (obj.show) {
          state.addDocument.id = obj.id ? obj.id : null;
          state.addDocument.type = obj.type ? obj.type : "";
          state.addDocument.documentUrl.front = obj.documentUrl.front ? obj.documentUrl.front : "";
          state.addDocument.documentUrl.back = obj.documentUrl.back ? obj.documentUrl.back : "";
          state.addDocument.documentUrl.pan = obj.documentUrl.pan ? obj.documentUrl.pan : "";
          // console.log('ffhdh',obj.documentUrl.front)
          // console.log('back',obj.documentUrl.back)
          // console.log('pan',obj.documentUrl.pan)
        }
        state.addDocument.show = obj.show;
        
      },
    },
    actions: {
      addDocument({ commit }, obj) {
        commit("ADD_DOCUMENT", obj);
      },
    },
  };
  