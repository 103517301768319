export default {
    namespaced: true,
    state: {
      addUnit: {
        type: "",
        id: "",
        show: false,
      },
    },
    getters: {
      getAddUnit: (state) => state.addUnit,
    },
    mutations: {
      ADD_UNIT(state, obj) {
        if (obj.show) {
          state.addUnit.id = obj.id ? obj.id : null;
          state.addUnit.type = obj.type ? obj.type : "";
        }
        state.addUnit.show = obj.show;
      },
    },
    actions: {
      addUnit({ commit }, obj) {
        commit("ADD_UNIT", obj);
      },
    },
  };
  