import moment from "moment";

export default {
  getCurrentDate(currentDate) {
    return moment(currentDate).format("YYYY-MM-DD");
  },
  timeFormat(time) {
    return moment(time, "HH:mm:ss").format("hh:mm A");
  },
  dateFormat(date) {
    return moment(date).format("DD-MM-YYYY");
    // moment().format('MMMM Do YYYY, h:mm:ss a');
  },
};
