export default {
  namespaced: true,
  state: {
    addTeamMember: {
      type: "",
      id: "",
      show: false,
    },
  },
  getters: {
    getAddTeamMember: (state) => state.addTeamMember,
  },
  mutations: {
    ADD_TEAMMEMBER(state, obj) {
      if (obj.show) {
        state.addTeamMember.id = obj.id ? obj.id : null;
        state.addTeamMember.type = obj.type ? obj.type : "";
      }
      state.addTeamMember.show = obj.show;
    },
  },
  actions: {
    addTeamMember({ commit }, obj) {
      commit("ADD_TEAMMEMBER", obj);
    },
  },
};
