const apiKeys = {
    //login
    Email: "email",
    Password: "password",
    CONFIRM_PASSWORD: "confirm_password",
    UID: "uid",
    TOKEN: "token",
    //passwordChange

    newPassword: "team_new_password",
    //commodities
    NAME: "name",
    DESCRIPTION1: "description1",
    DESCRIPTION2: "description2",
    AVAILABLE_UNIT: "available_unit",
    ABOUT: "about",
    // UNIT_KG: "unit_kg",
    IMAGE: "image",
};
export default apiKeys;
