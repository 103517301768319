export default{
    namespaced: true,
    state:{
        userDetail:{
            id: "",
            user_type: "",
        }      
    },
    getters:{
        getDetail: (state) => state.userDetail,
    },
    mutations: {
        USER_DETAIL_SET(state, obj) {
            state.userDetail.id = obj.id ? obj.id : null;
            state.userDetail.user_type = obj.user_type ? obj.user_type : null;
            // console.log(state.userDetail.id)
        },
      },
      actions: {
        userDetail({ commit }, obj) {
          commit("USER_DETAIL", obj);
        },
      },
    
}