export default {
    namespaced: true,
    state: {
      addComment: {
        type: "",
        id: "",
        show: false,
      },
      editAuction:{
        type: "",
        id: "",
        show: false,
      }
    },
    getters: {
      getAddComment: (state) => state.addComment,
      getEditAuction: (state) => state.editAuction,
    },
    mutations: {
      ADD_COMMENT(state, obj) {
        if (obj.show) {
          state.addComment.id = obj.id ? obj.id : null;
          state.addComment.type = obj.type ? obj.type : "";
        }
        state.addComment.show = obj.show;
      },
      EDIT_AUCTION(state,obj1){
        if (obj1.show) {
          state.editAuction.id = obj1.id ? obj1.id : null;
          state.editAuction.type = obj1.type ? obj1.type : "";
        }
        state.editAuction.show = obj1.show;
      }
    },
    actions: {
        addComment({ commit }, obj) {
        commit("ADD_COMMENT", obj);
      },
      editAuction({ commit }, obj1) {
        commit("EDIT_AUCTION", obj1);
      }
    },
  };
  