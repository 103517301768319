export default {
    namespaced: true,
    state: {
      addMandi: {
        type: "",
        id: "",
        show: false,
      },
    },
    getters: {
      getAddMandi: (state) => state.addMandi,
    },
    mutations: {
      ADD_MANDI(state, obj) {
        if (obj.show) {
          state.addMandi.id = obj.id ? obj.id : null;
          state.addMandi.type = obj.type ? obj.type : "";
        }
        state.addMandi.show = obj.show;
      },
    },
    actions: {
        addMandi({ commit }, obj) {
        commit("ADD_MANDI", obj);
      },
    },
  };
  