export default {
    namespaced: true,
    state: {
      verifyModal: {
        show: false,
        emit: "",
        id: null,
        text: "",
      },
    },
    getters: {
      getVerify: (state) => state.verifyModal,
    },
    mutations: {
  
      TOGGLE_VERIFY_MODAL(state, obj) {
        if (obj.show) {
          state.verifyModal.id = obj.id ? obj.id : null;
          state.verifyModal.emit = obj.emit ? obj.emit : "";
          state.verifyModal.text = obj.text ? obj.text : "";
        }
        state.verifyModal.show = obj.show;
      },
      
    },
    actions: {
      verifyModal({ commit }, obj) {
        commit("TOGGLE_VERIFY_MODAL", obj);
      },
    },
  };
  