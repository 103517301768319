import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth/index.js";
import commodities from "./commodities/index.js";
import snackBar from "./snackBar/index.js";
import facilityCenter from "./facilityCenter/index.js";
import teamManagement from "./teamManagement/index.js";
import offers from "./offers";
import saleContract from "./saleContract/index.js";
import cropMaster from "./cropMaster/index.js";
import unitMaster from "./unitMaster";
import app from "./app/index.js";
import confirmation from "./confirmation/index.js";
import auction from "./auction/index.js";
import farmer from "./farmer/index.js";
import contracts from "./contracts/index.js";
import activate from "./activate/index.js";
import userDetail from "./userDetail/index.js";
import trader from "./trader/index.js";
import verify from "./verify/index.js";
import farmManagement from "./farmManagement/index.js";
import cropManagement from "./cropManagement/index.js";
import mandi from "./mandi/index.js";
import mandiRate from "./mandiRate/index.js"
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    commodities,
    snackBar,
    facilityCenter,
    teamManagement,
    offers,
    saleContract,
    cropMaster,
    unitMaster,
    app,
    confirmation,
    auction,
    farmer,
    contracts,
    activate,
    userDetail,
    trader,
    verify,
    farmManagement,
    cropManagement,
    mandi,
    mandiRate,
  },
});
